<template>
  <div>
    <Top />
    <v-container style="max-width: 900px; padding-bottom: 69px" class="mt-2">
      <v-item-group v-model="selected" multiple>
        <v-row>
          <v-col cols="12" sm="4" style="padding: 2px !important">
            <v-item>
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
            <v-item style="margin-top:5px;">
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
            
          </v-col>
          <v-col cols="12" sm="8" style="padding: 2px !important">
            <v-item>
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, i) in list"
            :key="i"
            cols="12"
            sm="4"
            style="padding: 2px !important"
          >
            <v-item>
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" style="padding: 2px !important">
            <v-item>
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
            <v-item style="margin-top:5px;">
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
            
          </v-col>
          <v-col cols="12" sm="8" style="padding: 2px !important">
            <v-item>
              <v-img :src="avatar" class="text-right pa-2" @click="goTo()">
                <v-btn icon dark>
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-img>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-container>
    <bottom-navigation></bottom-navigation>
  </div>
</template>
<script>
export default {
  data() {
    return {
      avatar: require("@/assets/avatar.jpg"),
      post: require("@/assets/post.jpg"),
      list: [
        6,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        6,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        6,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        6,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
        4,
      ],
    };
  },
  methods: {
    goTo() {
      return this.$router.replace("/time_line");
    },
  },
};
</script>